import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden" ]
  
  static values = {
    hideAfterMs: Number
  }

  connect() {  
    if (this.hasHideAfterMsValue && this.hasHiddenClass) {
      this.timer = setTimeout(() => this.#hide(), this.hideAfterMsValue)
    }
  }

  disconnect() {
    this.stopTimer()
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

  // Private
  #hide() {
    this.element.classList.add(this.hiddenClass)
  }
}